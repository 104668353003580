import React from 'react';

import { Box, Button, Menu, MenuItem, Tooltip } from '@material-ui/core';
import GitHub from '@material-ui/icons/GitHub';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import ReorderIcon from '@material-ui/icons/Reorder';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';

import { NavigateToTemplateButton } from '../../NavigateToTemplateButton';

export const GroupOwnershipActions = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Tooltip title="Manage ownership of repositories and entities">
        <Button
          aria-controls="manage-ownership-menu"
          aria-haspopup="true"
          onClick={handleClick}
          startIcon={<ReorderIcon />}
        >
          Manage Ownership
        </Button>
      </Tooltip>
      <Menu
        id="manage-ownership-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <NavigateToTemplateButton
            startIcon={<GitHub />}
            variant="text"
            template="github-repository-ownership"
            text="Claim Repository Ownership"
          />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavigateToTemplateButton
            startIcon={<SettingsInputComponentIcon />}
            variant="text"
            template="batch-github-repository-ownership"
            text="Batch Confirm Repository Ownership"
          />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavigateToTemplateButton
            startIcon={<PeopleIcon />}
            variant="text"
            template="batch-update-entity-owners"
            text="Migrate Entities To Another Team"
          />
        </MenuItem>
      </Menu>
    </Box>
  );
};
