import BarChartIcon from '@mui/icons-material/BarChart';
import BlurLinearIcon from '@mui/icons-material/BlurLinear';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import React from 'react';

import { GroupFeature } from './types';

export const groupFeatures: GroupFeature[] = [
  {
    title: 'Update Azure Subscriptions',
    annotations: [
      {
        key: 'azure.com/subscription-names',
      },
    ],
    icon: <CloudQueueIcon />,
  },
  {
    title: 'Enable Github Workflows Monitor',
    annotations: [
      {
        key: 'tomtom.com/frontstage-pipeline-monitor',
      },
    ],
    icon: <LineAxisIcon />,
  },
  {
    title: 'Update Grafana Tag Selector',
    annotations: [
      {
        key: 'grafana/tag-selector',
      },
    ],
    icon: <BarChartIcon />,
  },
  {
    title: 'Update AKS ResourceName Filters',
    annotations: [
      {
        key: 'azure.com/aks-resourcename-filters',
      },
    ],
    icon: <BlurLinearIcon />,
  },
  {
    title: 'Toggle Landing Page',
    annotations: [
      {
        key: 'tomtom.com/landing-page-enabled',
        value: 'true',
      },
    ],
    icon: <ToggleOnIcon />,
  },
];
