export class ResourceQuota {
  cpuLimit: number;
  cpuRequest: number;
  memoryLimit: number;
  memoryRequest: number;

  constructor() {
    this.cpuLimit = 4000;
    this.cpuRequest = 4000;
    this.memoryLimit = 8192;
    this.memoryRequest = 8192;
  }
}

export class ArgoProject {
  repositories: string[] | null;
  helmRepositories: string[] | null;
  rootPath: string;

  constructor() {
    this.repositories = [];
    this.rootPath = '';
    this.helmRepositories = [];
  }
}
export class Tenant {
  name: string;
  tier: string;
  ownerObjectId: string;
  clusterId: string;
  stage: string;
  resourceQuota: ResourceQuota;
  namespaceQuota: number;
  slackChannel: string;
  nodeGroup: string;
  kubeConfig?: string;
  argoUrl?: string;
  argoProject?: ArgoProject | null;

  constructor() {
    this.name = '';
    this.tier = '';
    this.ownerObjectId = '';
    this.resourceQuota = new ResourceQuota();
    this.namespaceQuota = 3;
    this.slackChannel = '';
    this.argoProject = new ArgoProject();
    this.clusterId = '';
    this.stage = '';
    this.nodeGroup = '';
  }

  getClusterName(): string {
    return this.clusterId?.split('/')[1] ?? '';
  }

  getRegion(): string | undefined {
    return this.clusterId?.split('/')[1].split('-')[1];
  }

  getNodeGroup(): string | undefined {
    if (this.nodeGroup) {
      return this.nodeGroup;
    }
    return this.clusterId?.split('/')[1].split('-')[2];
  }

  getCpuRequest(): number {
    return this.resourceQuota.cpuRequest / 1000;
  }

  getCpuLimit(): number {
    return this.resourceQuota.cpuLimit / 1000;
  }

  getMemoryRequest(): number {
    return this.resourceQuota.memoryRequest / 1024;
  }

  getMemoryLimit(): number {
    return this.resourceQuota.memoryLimit / 1024;
  }

  getKubeconfig() {
    return atob(this.kubeConfig ?? '');
  }
}

export type ClusterNodeGroup = { name: string; owner: string };

export type Cluster = {
  id: string;
  name: string;
  tier: string;
  stage: string;
  nodeGroups: ClusterNodeGroup[];
};

export type ClusterOption = {
  groupName: string;
  label: string;
  value: string;
  tier: string;
  stage: string;
  nodeGroup: string;
};

export type SelectInputOption = {
  label: string;
  value: string;
  [key: string]: string;
} | null;

export type ValidationError = { valid: boolean; error: string | undefined };

export type TenantFilter = {
  type: string;
  value: string;
  matches: (value: string, tenant: Tenant) => boolean;
};

export const TIER_FILTER_ALL = 'all';
