import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';

import { VulnerabilityData } from '../types';

export interface WatchtowerApi {
  get(path: string): Promise<VulnerabilityData>;
  post(
    path: string,
    data: { repoNames: string[]; period?: string },
  ): Promise<VulnerabilityData>;
}

export const watchtowerApiRef = createApiRef<WatchtowerApi>({
  id: 'plugin.watchtower.service',
});

export class WatchtowerClient implements WatchtowerApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  private async getBaseUrl() {
    const baseUrl = await this.discoveryApi.getBaseUrl('watchtower');
    return baseUrl;
  }

  async get(path: string): Promise<VulnerabilityData> {
    const baseUrl = await this.getBaseUrl();
    const response = await this.fetchApi.fetch(`${baseUrl}${path}`);
    if (!response.ok) {
      throw new Error(`Failed to fetch ${path}: ${response.statusText}`);
    }
    return await response.json();
  }

  async post(
    path: string,
    data: { repoNames: string[]; period?: string },
  ): Promise<VulnerabilityData> {
    const baseUrl = await this.getBaseUrl();
    const response = await this.fetchApi.fetch(`${baseUrl}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error(`Failed to post to ${path}: ${response.statusText}`);
    }
    return await response.json();
  }
}
