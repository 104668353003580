import { FactRetrieverContext } from '@backstage-community/plugin-tech-insights-node';
import { Entity } from '@backstage/catalog-model';

import { getEntities } from '../../handlers/utils/getEntities';
import { OwnershipFact, TypedFactRetriever } from '../../types';

export const componentEntityOwnershipFactRetriever: TypedFactRetriever<OwnershipFact> =
  {
    id: 'componentEntityOwnershipFactRetriever',
    version: '0.0.1',
    title: 'Entity Ownership',
    description:
      'Generates facts which indicate the quality of data in the spec.owner field',
    entityFilter: [{ kind: ['component'] }],
    schema: {
      hasOwner: {
        type: 'boolean',
        description: 'The spec.owner field is set',
      },
      hasGroupOwner: {
        type: 'boolean',
        description: 'The spec.owner field is set and refers to a group',
      },
    },
    handler: async ({
      discovery,
      entityFilter,
      tokenManager,
    }: FactRetrieverContext) => {
      const entities = await getEntities({
        entityFilter,
        tokenManager,
        discovery,
      });

      return entities.items.map((entity: Entity) => {
        const hasOwner =
          Boolean(entity.spec?.owner) && entity.spec?.owner !== 'unknown';
        const hasGroupOwner =
          hasOwner && (entity.spec?.owner as string).startsWith('group:');
        return {
          entity: {
            namespace: entity.metadata.namespace!,
            kind: entity.kind,
            name: entity.metadata.name,
          },
          facts: {
            hasOwner,
            hasGroupOwner,
          },
        };
      });
    },
  };
