import React from 'react';

import { Box } from '@material-ui/core';

import { GroupAddActions } from './GroupAddActions';
import { GroupConfigActions } from './GroupConfigActions';
import { GroupEnableFeatureActions } from './GroupEnableFeatureActions';
import { GroupOwnershipActions } from './GroupOwnershipActions';

export const GroupEntityActionMenu = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        p: 1,
        m: 1,
        borderRadius: 0,
      }}
    >
      <GroupConfigActions />
      <GroupOwnershipActions />
      <GroupAddActions />
      <GroupEnableFeatureActions />
    </Box>
  );
};
