import { TierColors } from '@internal/backstage-plugin-scorecard';
import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import { Scorecard } from '../../types';
import { CircularValueDisplay } from '../CircularValueDisplay';
import { TierBadge } from '../TierBadge';

interface ScoreChartsProps {
  data: Scorecard;
}

const getTierByValue = (value: number) => {
  if (value >= 90) {
    return 'Gold';
  } else if (value >= 75) {
    return 'Silver';
  } else if (value >= 40) {
    return 'Bronze';
  }
  return 'Black';
};

const getCategoryChart = (category: string, value: number | null) => {
  return (
    <>
      <Grid item container md={12}>
        <Typography
          variant="body2"
          component="div"
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '-10px',
          }}
        >
          {category}
        </Typography>
      </Grid>
      <Grid item container md={12}>
        <CircularValueDisplay
          value={value ?? 0}
          size="Small"
          color={TierColors[getTierByValue(value ?? 0)]}
        />
      </Grid>
    </>
  );
};

export const ScoreCharts = ({ data }: ScoreChartsProps) => {
  if (!data) return null;

  return (
    <Grid item container md={12}>
      <Grid item container md={6}>
        <Grid item container md={6}>
          <Typography variant="h6">Overall Score</Typography>
        </Grid>
        <Grid
          item
          container
          md={6}
          style={{ marginTop: '-2px', marginLeft: 'auto' }}
        >
          <TierBadge tier={data.tier} />
        </Grid>
        <Grid item container md={12} style={{ marginTop: '10px' }}>
          <CircularValueDisplay
            value={data.overallScore}
            size="Regular"
            color={TierColors[data.tier]}
          />
        </Grid>
      </Grid>
      <Grid item container md={6}>
        <Grid item container md={6}>
          {getCategoryChart('Ownership', data.categories?.ownership!)}
        </Grid>
        <Grid item container md={6}>
          {getCategoryChart('Security', data.categories?.security!)}
        </Grid>
        <Grid item container md={6}>
          {getCategoryChart('DevOps', data.categories?.quality!)}
        </Grid>
        <Grid item container md={6}>
          {getCategoryChart('Reliability', data.categories?.reliability!)}
        </Grid>
      </Grid>
    </Grid>
  );
};
