import { KaaPTenantEntity } from '@internal/plugin-catalog-model';

import { Entity } from '@backstage/catalog-model';
import { CatalogProcessor } from '@backstage/plugin-catalog-node';

import { OptionsType, PROCESSOR_NAME } from './types';
import { isKaaPTenantEntity } from './utils';

enum AzureRegion {
  'WestEurope' = 'westeurope',
  'NorthEurope' = 'northeurope',
  'Southeast Asia' = 'southeastasia',
  'Korea' = 'korea',
  'Korea Central' = 'koreacentral',
  'Korea South' = 'koreasouth',
  'East Asia' = 'eastasia',
  'South India' = 'southindia',
  'West India' = 'westindia',
  'UK South' = 'uksouth',
  'UK West' = 'ukwest',
  'East US' = 'eastus',
  'West US' = 'westus',
  'South Central US' = 'southcentralus',
  'North Central US' = 'northcentralus',
  'West Central US' = 'westcentralus',
}

const validateEntitySpec = (entity: KaaPTenantEntity): boolean => {
  let isValidSpec = true;

  const {
    spec: {
      tier,
      azureSubscriptionId,
      region,
      namespaceQuota,
      resourceQuota,
      type,
    },
  } = entity;
  if (!tier || !['playground', 'internal', 'external'].includes(tier)) {
    isValidSpec = false;
  }

  if (!azureSubscriptionId) {
    isValidSpec = false;
  }

  if (!region || !Object.values(AzureRegion).includes(region as AzureRegion)) {
    isValidSpec = false;
  }

  if (isNaN(namespaceQuota) || namespaceQuota <= 0) {
    isValidSpec = false;
  }

  if (
    !resourceQuota ||
    isNaN(resourceQuota.cpuLimit) ||
    resourceQuota.cpuLimit <= 0 ||
    isNaN(resourceQuota.cpuRequest) ||
    resourceQuota.cpuRequest <= 0 ||
    isNaN(resourceQuota.memoryLimit) ||
    resourceQuota.memoryLimit <= 0 ||
    isNaN(resourceQuota.memoryRequest) ||
    resourceQuota.memoryRequest <= 0
  ) {
    isValidSpec = false;
  }

  if (!type || !['tenant'].includes(type)) {
    isValidSpec = false;
  }

  return isValidSpec;
};

export class KaaPTenantProcessor implements CatalogProcessor {
  static buildKaaPTenantProcessor(_: OptionsType): KaaPTenantProcessor {
    return new KaaPTenantProcessor();
  }

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {}

  getProcessorName = (): string => PROCESSOR_NAME;

  async validateEntityKind(entity: Entity): Promise<boolean> {
    if (!isKaaPTenantEntity(entity)) {
      return false;
    }
    const kaaPTenantEntity = entity as KaaPTenantEntity;

    if (!kaaPTenantEntity.spec) {
      return false;
    }

    if (!validateEntitySpec(kaaPTenantEntity)) {
      return false;
    }

    return true;
  }
}
