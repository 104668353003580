import { Client } from '@microsoft/microsoft-graph-client';
import { TokenCredentialAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/azureTokenCredentials';

import { createApiRef } from '@backstage/core-plugin-api';

export const armApiRef = createApiRef<ArmApi>({
  id: 'plugin.kaap.armapi',
});

export interface ArmApi {
  getAzureAdOwnGroups(
    authProvider: TokenCredentialAuthenticationProvider,
  ): Promise<any>;
  getAzureAdGroup(
    groupId: string,
    authProvider: TokenCredentialAuthenticationProvider,
  ): Promise<any>;
}

const WELL_KNOWN_GROUPS = [
  'dcedc4ca-ef7d-4e38-8f98-713505aa017b', // TomTom Users
  'b2f412ba-da2f-4adf-a171-8439a18822e7', // RG_AZ_GTMHUB_Access_Employees
  '5543ef50-dfb8-4209-9675-30633be0e91c', // RG_MS_LIC_M365_E5_Suite_End_User_Global_Standard
  '62376fb8-8ecd-4b5d-9283-f8c90855ce12', // RG_App_Library
  '97ed1212-89a3-45b8-8ab0-795197fb3bb2', // RG_AZ_OPEN_MAPS_Viewer_All_Employees_Contractors_Dev
  'cf7e0dca-ea6e-4851-a081-a0c9a4e82b2e', // RG_AZ_Allow_O365_Group_Creation
  'f5e93fdd-1c6f-475d-bea2-2c24ed90a8a1', // test_dynamic_allusercontractor
];

export class ArmApiClient implements ArmApi {
  async getAzureAdGroup(
    groupId: string,
    authProvider: TokenCredentialAuthenticationProvider,
  ): Promise<any> {
    const graphClient = Client.initWithMiddleware({
      authProvider,
      fetchOptions: {
        headers: { ConsistencyLevel: 'eventual' },
      },
    });

    return graphClient.api(`/groups/${groupId}`).get();
  }
  async getAzureAdOwnGroups(
    authProvider: TokenCredentialAuthenticationProvider,
  ): Promise<any> {
    const graphClient = Client.initWithMiddleware({
      authProvider,
      fetchOptions: {
        headers: { ConsistencyLevel: 'eventual' },
      },
    });

    const groups = await graphClient
      .api('/me/memberOf/$/microsoft.graph.group?$count=true')
      .filter('mailEnabled eq false and securityEnabled eq true')
      .select('id,displayName')
      .get();

    return groups.value.filter((group: { id: string; displayName: string }) => {
      return (
        !WELL_KNOWN_GROUPS.includes(group.id) &&
        !group.displayName?.startsWith('RG_AZ_PIM_') &&
        !group.displayName?.toUpperCase().startsWith('RG_AZ_APP_') &&
        !group.displayName?.toUpperCase().startsWith('RG_APP_') &&
        !group.displayName?.toUpperCase().startsWith('RG_AZ_TT_') &&
        !group.displayName?.toUpperCase().startsWith('RG_AZ_LOC_') &&
        !group.displayName?.toUpperCase().startsWith('SPO_LOCATION_') &&
        !group.displayName?.toUpperCase().startsWith('RG_VPN_') &&
        !group.displayName
          ?.toUpperCase()
          .startsWith('RG_AZ_Slack_User_Group_'.toUpperCase()) &&
        !group.displayName
          ?.toUpperCase()
          .startsWith('PG_concur_SSO'.toUpperCase()) &&
        !group.displayName
          ?.toUpperCase()
          .startsWith('RG_AZ_CloudSpend_Readers'.toUpperCase())
      );
    });
  }
}
