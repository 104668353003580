import { TeamLevelChecks } from '../../checkIDs';

export const teamInfraVulnerabilityChecks = [
  {
    id: TeamLevelChecks.CSPM_HIGH_CHECK,
    type: 'JSON_RULE_ENGINE_CHECK_TYPE',
    name: 'CSPM High Severity Findings Check',
    description:
      'Verfifies that there are no high severity Cloud Security Posture Management findings',
    factIds: ['teamVulnerabilitiesInfoFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'CSPMHigh',
            operator: 'equal',
            value: 0,
          },
        ],
      },
    },
  },
  {
    id: TeamLevelChecks.CSPM_MEDIUM_CHECK,
    type: 'JSON_RULE_ENGINE_CHECK_TYPE',
    name: 'CSPM Medium Severity Findings Check',
    description:
      'Verfifies that there are no medium severity Cloud Security Posture Management findings',
    factIds: ['teamVulnerabilitiesInfoFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'CSPMMedium',
            operator: 'equal',
            value: 0,
          },
        ],
      },
    },
  },
  {
    id: TeamLevelChecks.VulnerabilityManagementCoverage,
    type: 'JSON_RULE_ENGINE_CHECK_TYPE',
    name: 'Percentage of assets covered by vulnerability management',
    description:
      'Verifies that the percentage of assets covered by vulnerability management is 100%',
    factIds: ['teamVulnerabilitiesInfoFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'VulnerabilityManagementCoverage',
            operator: 'greaterThanInclusive',
            value: 90,
          },
        ],
      },
    },
  },
  {
    id: TeamLevelChecks.VulnerabilityManagementCritical,
    type: 'JSON_RULE_ENGINE_CHECK_TYPE',
    name: 'Number of critical vulnerability management findings',
    description:
      'Verifies that the number of critical vulnerability management findings is 0',
    factIds: ['teamVulnerabilitiesInfoFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'VulnerabilityManagementCoverage',
            operator: 'equal',
            value: 0,
          },
        ],
      },
    },
  },
  {
    id: TeamLevelChecks.VulnerabilityManagementHigh,
    type: 'JSON_RULE_ENGINE_CHECK_TYPE',
    name: 'Number of high vulnerability management findings',
    description:
      'Verifies that the number of high vulnerability management findings is 0',
    factIds: ['teamVulnerabilitiesInfoFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'VulnerabilityManagementHigh',
            operator: 'equal',
            value: 0,
          },
        ],
      },
    },
  },
  {
    id: TeamLevelChecks.PIM,
    type: 'JSON_RULE_ENGINE_CHECK_TYPE',
    name: 'Privileged Identity Management score',
    description:
      'Verifies that the Privileged Identity Management score is 100%',
    factIds: ['teamVulnerabilitiesInfoFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'PIM',
            operator: 'equal',
            value: 100,
          },
        ],
      },
    },
  },
  {
    id: TeamLevelChecks.KRSP,
    type: 'JSON_RULE_ENGINE_CHECK_TYPE',
    name: 'Key Rotation Security Posture score',
    description:
      'Verifies that the percentage of keys rotated frequently is > 90%',
    factIds: ['teamVulnerabilitiesInfoFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'KRSP',
            operator: 'greaterThanInclusive',
            value: 90,
          },
        ],
      },
    },
  },
  {
    id: TeamLevelChecks.KRIAM,
    type: 'JSON_RULE_ENGINE_CHECK_TYPE',
    name: 'Key Rotation Identity Access Management score',
    description: 'Verifies that the Key rotations for IAM Users is compliant',
    factIds: ['teamVulnerabilitiesInfoFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'KRIAM',
            operator: 'greaterThanInclusive',
            value: 90,
          },
        ],
      },
    },
  },
  {
    id: TeamLevelChecks.InformationClassificationAWS,
    type: 'JSON_RULE_ENGINE_CHECK_TYPE',
    name: 'Information classification score for AWS resources',
    description:
      'Verifies that Information Classification compliance for AWS resources is 100%',
    factIds: ['teamVulnerabilitiesInfoFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'InformationClassificationAWS',
            operator: 'equal',
            value: 100,
          },
        ],
      },
    },
  },
  {
    id: TeamLevelChecks.InformationClassificationAzure,
    type: 'JSON_RULE_ENGINE_CHECK_TYPE',
    name: 'Information classification score for Azure resources',
    description:
      'Verifies that Information Classification compliance for Azure resources is 100%',
    factIds: ['teamVulnerabilitiesInfoFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'InformationClassificationAzure',
            operator: 'equal',
            value: 100,
          },
        ],
      },
    },
  },
  {
    id: TeamLevelChecks.CodeVulnerabilitiesSCACoverage,
    type: 'JSON_RULE_ENGINE_CHECK_TYPE',
    name: 'SCA Coverage',
    description:
      'Verifies that the Software Composition Analysis coverage is 100%',
    factIds: ['teamVulnerabilitiesInfoFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'CodeVulnerabilitiesSCACoverage',
            operator: 'equal',
            value: 100,
          },
        ],
      },
    },
  },
  {
    id: TeamLevelChecks.CodeVulnerabilitiesSASTCoverage,
    type: 'JSON_RULE_ENGINE_CHECK_TYPE',
    name: 'SAST Coverage',
    description:
      'Verifies that the Static Application Security Testing coverage is 100%',
    factIds: ['teamVulnerabilitiesInfoFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'CodeVulnerabilitiesSASTCoverage',
            operator: 'equal',
            value: 100,
          },
        ],
      },
    },
  },
  {
    id: TeamLevelChecks.EndofLife,
    type: 'JSON_RULE_ENGINE_CHECK_TYPE',
    name: 'End of Life Operating Systems versions check',
    description:
      'Verifies that no cloud assets are running OSes that have reached End of Life',
    factIds: ['teamVulnerabilitiesInfoFactRetriever'],
    rule: {
      conditions: {
        all: [
          {
            fact: 'EndofLife',
            operator: 'equal',
            value: 100,
          },
        ],
      },
    },
  },
];
