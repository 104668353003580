import { Entity } from '@backstage/catalog-model';

import { GroupFeature } from './types';

export const loadEntityFeatures = (
  entity: Entity,
  groupFeatures: GroupFeature[],
): GroupFeature[] => {
  return groupFeatures.map((groupFeature: GroupFeature) => {
    const annotations = groupFeature.annotations.map(annotation => ({
      key: annotation.key,
      value: entity.metadata.annotations?.[annotation.key] ?? '',
    }));
    return {
      title: groupFeature.title,
      icon: groupFeature.icon,
      annotations,
    };
  });
};
