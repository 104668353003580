import { GroupEntity } from '@internal/plugin-catalog-model';

import { Entity } from '@backstage/catalog-model';
import { IdentityApi } from '@backstage/core-plugin-api';
import { CatalogApi } from '@backstage/plugin-catalog-react';
import { JsonObject } from '@backstage/types';

import { arrayToObjectReducer } from '../utils';

const loadTeamManagers = (entity: Entity): JsonObject[] => {
  return (
    (entity as GroupEntity).metadata.managers?.map(manager => ({
      title: manager.title ?? '',
      name: manager.name,
      email: manager.email ?? '',
    })) ?? []
  );
};

export const teamConfigFormDataBuilder = async (
  entity: Entity,
  formFields: string[],
  _identityApi?: IdentityApi,
  _catalogApi?: CatalogApi,
) => {
  const formData = formFields
    .map((field: string) => {
      switch (field) {
        case 'displayName':
          return { [field]: entity.metadata.title ?? '' };
        case 'team':
          return { [field]: `group:${entity.metadata.name}` };
        case 'managers':
          return { [field]: loadTeamManagers(entity) };
        case 'mission':
          return { [field]: (entity as GroupEntity).metadata.mission ?? '' };
        case 'vision':
          return { [field]: (entity as GroupEntity).metadata.vision ?? '' };
        case 'purpose':
          return { [field]: (entity as GroupEntity).metadata.purpose ?? '' };
        case 'slack':
          return { [field]: (entity as GroupEntity).metadata.slack ?? {} };
        case 'confluence':
          return { [field]: (entity as GroupEntity).metadata.confluence ?? {} };
        case 'bugReport':
          return { [field]: (entity as GroupEntity).metadata.bugReport ?? {} };
        case 'email':
          return {
            [field]: {
              email: (entity as GroupEntity).spec.profile?.email ?? '',
            },
          };
        case 'links':
          return { [field]: (entity as GroupEntity).metadata.links ?? [] };
        default:
          return {};
      }
    })
    .reduce(arrayToObjectReducer, {});

  return formData;
};
