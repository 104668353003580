import axios, { AxiosInstance } from 'axios';
import https from 'https';
import { Logger } from 'winston';

export class VulnerabilitiesInfoReader {
  private readonly endpoint: string;
  private readonly authorizationKey: string | undefined;
  axiosInstance: AxiosInstance;
  private readonly logger: Logger;

  constructor(endpoint: string, logger: Logger, authorizationKey?: string) {
    this.logger = logger;
    this.endpoint = endpoint;
    this.authorizationKey = authorizationKey;
    // accept self-signed certificates
    this.axiosInstance = axios.create({
      httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    });
  }

  async getVulnerabiltiesStats<T>(costCenterName: string): Promise<T[] | null> {
    if (!this.authorizationKey) {
      throw new Error(
        'VulnerabilitiesInfoReader, authorization key not configured',
      );
    }
    const start = process.hrtime();
    const response = await (this.axiosInstance as AxiosInstance).post(
      `${this.endpoint}`,
      {
        CostCenter: costCenterName,
        AuthorizationKey: this.authorizationKey,
      },
      { timeout: 20000, headers: { 'Content-Type': 'application/json' } },
    );
    const end = process.hrtime(start);
    this.logger.info(
      `Time taken to fetch vulnerabilities for costCenter ${costCenterName} is ${
        end[0]
      }s ${end[1] / 1000000}ms`,
    );
    if (response.status !== 200) {
      this.logger.error(
        `Unexpected error fetching vulnerability information for ${costCenterName}`,
      );
      throw new Error(
        `Failed to fetch vulnerabilities information for cost centers ${costCenterName}`,
      );
    }
    return response.data ? (response.data as T[]) : null;
  }

  async getAllVulnerabilities<T>(): Promise<T[] | null> {
    try {
      return await this.getVulnerabiltiesStats<T>('All');
    } catch (error) {
      this.logger.error(
        `Failed to fetch vulnerabilities information for all cost centers`,
        error,
      );
      return null;
    }
  }
}
