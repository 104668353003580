import { useCommonStyles } from '@internal/plugin-tomtom-theme-react';
import React from 'react';

import { ChatIcon, GitHubIcon } from '@backstage/core-components';
import { HomePageToolkit } from '@backstage/plugin-home';

import { Grid } from '@material-ui/core';
import AppsIcon from '@material-ui/icons/Apps';
import BusinessIcon from '@material-ui/icons/Business';
import CloudIcon from '@material-ui/icons/Cloud';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import ShowChartOutlinedIcon from '@material-ui/icons/ShowChartOutlined';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import { useHomePageStyles } from './hooks/useHomePageStyles';

const toolkitLinks = [
  {
    url: 'https://tomtominternational.sharepoint.com/sites/intouch2/Pages/TomTom-Home.aspx',
    label: 'The Beat',
    icon: <BusinessIcon />,
  },
  {
    url: 'https://portal.azure.com/#home',
    label: 'Azure Portal',
    icon: <CloudIcon />,
  },
  {
    url: 'https://portal.azure.com/#view/Microsoft_Azure_PIMCommon/ActivationMenuBlade/~/azurerbac',
    label: 'Azure PIM',
    icon: <SupervisorAccountIcon />,
  },
  {
    url: 'https://vault.tomtomgroup.com/onboarding',
    label: 'Request Vault Namespace',
    icon: <VpnKeyIcon />,
  },
  {
    url: 'https://github.com/tomtom-internal/sp-sde-portal/issues/new?assignees=&labels=&template=bug_report.md&title=',
    label: 'Report An Issue',
    icon: <GitHubIcon />,
  },
  {
    url: 'https://tomtomslack.slack.com/archives/C034GEAF6RM',
    label: 'FrontStage Slack Support Channel',
    icon: <ChatIcon />,
  },
  {
    url: '/docs/default/component/sp-dx-golden-path/#what-is-the-golden-path',
    label: 'Golden Path',
    icon: <ShowChartOutlinedIcon />,
  },
  {
    url: 'https://tomtom.atlassian.net/wiki/display/OTSKB/List+of+approved+Applications',
    label: 'Approved Applications',
    icon: <PlaylistAddCheckIcon />,
  },
  {
    url: 'https://products.tomtomgroup.com/dashboard',
    label: 'Product board',
    icon: <AppsIcon />,
  },
  {
    url: '/secrets-sharing',
    label: 'Share secrets',
    icon: <AppsIcon />,
  },
  {
    url: '/github-app-creation',
    label: 'Create Github App',
    icon: <GitHubIcon />,
  },
];

export const HomeToolKit = () => {
  const classes = useHomePageStyles();
  const commonStyles = useCommonStyles();
  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      spacing={0}
      className={`${classes.toolkit} ${commonStyles.paddingTop25}`}
    >
      <Grid item xs={12}>
        <HomePageToolkit tools={toolkitLinks} />
      </Grid>
    </Grid>
  );
};
