import React from 'react';

import { CompoundEntityRef } from '@backstage/catalog-model';
import { useRouteRef } from '@backstage/core-plugin-api';
import { EntityRefLink, entityRouteRef } from '@backstage/plugin-catalog-react';

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import AccountTreeIcon from '@material-ui/icons/AccountTree';

export const GroupProfileParentsListItem = (props: {
  parents: CompoundEntityRef[];
}) => {
  const entityRoute = useRouteRef(entityRouteRef);
  if (props.parents.length === 0) {
    return <></>;
  }
  return (
    <ListItem data-testid="parent-list">
      <ListItemIcon>
        <Tooltip title="Parent Group">
          <AccountTreeIcon />
        </Tooltip>
      </ListItemIcon>
      <ListItemText
        primary={
          props.parents.length
            ? props.parents.map(parentRef => (
                <EntityRefLink
                  onClick={() => {
                    const url = entityRoute(parentRef);
                    window.location.href = url;
                  }}
                  entityRef={parentRef}
                  defaultKind="Group"
                />
              ))
            : 'N/A'
        }
        secondary="Parent Group"
      />
    </ListItem>
  );
};
