import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { WatchtowerClient, watchtowerApiRef } from './api';
import { rootRouteRef } from './routes';

export const watchtowerDashboardPlugin = createPlugin({
  id: 'watchtower-dashboard',
  apis: [
    createApiFactory({
      api: watchtowerApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new WatchtowerClient({ discoveryApi, fetchApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const WatchtowerDashboardPage = watchtowerDashboardPlugin.provide(
  createRoutableExtension({
    name: 'WatchtowerDashboardPage',
    component: () =>
      import('./components/Dashboard/Dashboard').then(m => m.Dashboard),
    mountPoint: rootRouteRef,
  }),
);
