import {
  FactRetrieverContext,
  TechInsightFact,
} from '@backstage-community/plugin-tech-insights-node';

import { getTeamComponentOrAPIEntities } from '../utils/getTeamComponentOrAPIEntities';

export const teamComponentOrAPIOnboardingHandler = async ({
  discovery,
  tokenManager,
}: FactRetrieverContext): Promise<TechInsightFact[]> => {
  const response = await getTeamComponentOrAPIEntities({
    tokenManager,
    discovery,
  });

  return response
    .filter(result => result.status === 'fulfilled')
    .map(
      result =>
        (
          result as PromiseFulfilledResult<{
            namespace: string;
            kind: string;
            name: string;
            ownedComponentOrAPICount: number;
          }>
        ).value,
    )
    .map(item => ({
      entity: {
        namespace: item.namespace,
        kind: item.kind,
        name: item.name,
      },
      facts: {
        isComponentOrAPIOnboard: item.ownedComponentOrAPICount > 0,
      },
    }));
};
