import { FactRetriever } from '@backstage-community/plugin-tech-insights-node';

import { teamConfluenceHandler } from '../../handlers/team';

export const teamConfluenceFactRetriever: FactRetriever = {
  id: 'teamConfluenceFactRetriever',
  version: '0.0.1',
  title: 'Team Confluence',
  description:
    'Generates facts which indicate the quality of data in metadata.confluence field',
  entityFilter: [{ kind: ['group'] }],
  schema: {
    hasConfluence: {
      type: 'boolean',
      description: 'The metadata.confluence field is set',
    },
  },
  handler: teamConfluenceHandler,
};
