import { VulnerabilitiesInfoReader } from '../../VulnerabilitiesInfoReader';
import { teamVulnerabilitiesInfoHandler } from '../../handlers/team';
import {
  CostCenterVulnerabilitiesScore,
  TypedFactRetriever,
} from '../../types';

export const createTeamVulnerabilitiesInfoFactRetriever = (
  vulnerabilitiesInfoReader: VulnerabilitiesInfoReader,
): TypedFactRetriever<CostCenterVulnerabilitiesScore> => {
  return {
    id: 'teamVulnerabilitiesInfoFactRetriever',
    version: '0.0.1',
    title: 'Team Missions',
    description:
      'Fetch software and infra vulnerabilities in assets owned by the team',
    // are the filters correct?
    entityFilter: [
      { kind: ['group'], 'spec.type': ['cost-center', 'supervisory-org'] },
    ],
    schema: {
      CSPMHigh: {
        type: 'integer',
        description:
          'Number of high severity Cloud Security Posture Management findings',
      },
      CSPMMedium: {
        type: 'integer',
        description:
          'Number of medium severity Cloud Security Posture Management findings',
      },
      CodeVulnerabilitiesTotalRepos: {
        type: 'integer',
        description: 'Total number of repositories with code vulnerabilities',
      },
      CodeVulnerabilitiesCritical: {
        type: 'integer',
        description: 'Number of critical code vulnerabilities detected',
      },
      CodeVulnerabilitiesHigh: {
        type: 'integer',
        description: 'Number of high severity code vulnerabilities detected',
      },
      CodeVulnerabilitiesSASTCoverage: {
        type: 'integer',
        description: 'Static Application Security Testing coverage percentage',
      },
      CodeVulnerabilitiesSCACoverage: {
        type: 'integer',
        description: 'Software Composition Analysis coverage percentage',
      },
      CostCenter: {
        type: 'string',
        description: 'Identifier for the cost center',
      },
      InformationClassificationAWS: {
        type: 'integer',
        description: 'Information classification score for AWS resources',
      },
      InformationClassificationAzure: {
        type: 'integer',
        description: 'Information classification score for Azure resources',
      },
      KRIAM: {
        type: 'integer',
        description: 'Key Risk Indicator for Access Management score',
      },
      KRSP: {
        type: 'integer',
        description: 'Key Risk Indicator for Security Posture score',
      },
      PIM: {
        type: 'integer',
        description: 'Privileged Identity Management score',
      },
      VulnerabilityManagementCoverage: {
        type: 'integer',
        description: 'Percentage of assets covered by vulnerability management',
      },
      VulnerabilityManagementCritical: {
        type: 'integer',
        description: 'Number of critical vulnerability management findings',
      },
      VulnerabilityManagementHigh: {
        type: 'integer',
        description:
          'Number of high severity vulnerability management findings',
      },
      EndofLife: {
        type: 'integer',
        description: 'Number of end-of-life components or systems',
      },
    },
    handler: teamVulnerabilitiesInfoHandler(vulnerabilitiesInfoReader),
  };
};
