import { Scorecard, ScorecardNode, ScorePerTier } from '../types';

export const getCategories = (data: Scorecard) => {
  return data?.categories ? Object.keys(data.categories) : [];
};

export const summariseScores = (items: ScorecardNode[] | []) => {
  const emptyScores: ScorePerTier = {
    Gold: 0,
    Silver: 0,
    Bronze: 0,
    Black: 0,
  };
  return items.reduce<ScorePerTier>(
    (acc: ScorePerTier, scorecardNode: ScorecardNode) => {
      const scorecard = scorecardNode as Scorecard;
      if (
        scorecard.itemType.toLocaleLowerCase() === 'service' ||
        scorecard.itemType.toLocaleLowerCase() === 'group'
      ) {
        return {
          Gold: acc.Gold + (scorecard.tier === 'Gold' ? 1 : 0),
          Silver: acc.Silver + (scorecard.tier === 'Silver' ? 1 : 0),
          Bronze: acc.Bronze + (scorecard.tier === 'Bronze' ? 1 : 0),
          Black: acc.Black + (scorecard.tier === 'Black' ? 1 : 0),
        };
      }

      const childrenResults: ScorePerTier = summariseScores(scorecard.items);
      return {
        Gold: acc.Gold + childrenResults.Gold,
        Silver: acc.Silver + childrenResults.Silver,
        Bronze: acc.Bronze + childrenResults.Bronze,
        Black: acc.Black + childrenResults.Black,
      };
    },
    emptyScores,
  );
};

export const getHeaders = (token: string | undefined): HeadersInit => {
  const headers: HeadersInit = new Headers();
  headers.set('content-type', 'application/json');
  headers.set('Authorization', `Bearer ${token}`);
  return headers;
};
