import React from 'react';

import { getCompoundEntityRef } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
} from '@material-ui/core';

import { useGetScorecardData } from '../../hooks';
import { Scorecard } from '../../types';
import { ScoreCharts } from '../ScoreCharts';
import { ScoreCheckTable } from '../ScoreChecksTable';

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  gridItemCardContent: {
    flex: 1,
  },
});

type ScorecardProps = {
  // if true, we only show the checks applicable only to the entity
  // and do not request scorecard info for sub items of the entity
  entityLevelChecksOnly?: boolean;
};
export const EntityScorecard = (props: ScorecardProps) => {
  const { entity } = useEntity();
  const { scorecard, loading, error } = useGetScorecardData(
    getCompoundEntityRef(entity),
    props.entityLevelChecksOnly,
  );
  const classes = useStyles();
  return (
    <Card className={classes.gridItemCard}>
      <CardHeader title="Scorecard" />
      {!loading && !error && (
        <CardContent className={classes.gridItemCardContent}>
          <Grid container>
            <Grid item container md={6}>
              <ScoreCharts data={scorecard as Scorecard} />
            </Grid>
            <Grid item container md={6}>
              <ScoreCheckTable scorecard={scorecard as Scorecard} />
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
};
