import React from 'react';

import { Box, Button, Menu, MenuItem, Tooltip } from '@material-ui/core';
import AddRounded from '@material-ui/icons/AddRounded';
import HubIcon from '@material-ui/icons/DeviceHub';
import GitHub from '@material-ui/icons/GitHub';

import { NavigateToTemplateButton } from '../../NavigateToTemplateButton';

export const GroupAddActions = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Tooltip title="Add service, repository etc.">
        <Button
          aria-controls="add-items-menu"
          aria-haspopup="true"
          onClick={handleClick}
          startIcon={<AddRounded />}
        >
          Add Items
        </Button>
      </Tooltip>
      <Menu
        id="add-items-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <NavigateToTemplateButton
            startIcon={<HubIcon />}
            variant="text"
            template="create-service"
            text="Add Service"
          />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Button
            variant="text"
            startIcon={<GitHub />}
            href="/create/templates/default/github-basic-repo-creation"
          >
            Create Repository
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Button
            variant="text"
            startIcon={<AddRounded />}
            href="/catalog-import"
          >
            Import Catalog
          </Button>
        </MenuItem>
      </Menu>
    </Box>
  );
};
