import { FactRetriever } from '@backstage-community/plugin-tech-insights-node';

import { teamManagerHandler } from '../../handlers/team';

export const teamManagerFactRetriever: FactRetriever = {
  id: 'teamManagerFactRetriever',
  version: '0.0.1',
  title: 'Team Manager',
  description:
    'Generates facts which indicate the quality of data in metadata.manager field',
  entityFilter: [{ kind: ['group'] }],
  schema: {
    hasManagers: {
      type: 'boolean',
      description: 'The metadata.managers field is set',
    },
    hasManagerTitles: {
      type: 'boolean',
      description: 'The metadata.managers[*].title field is set',
    },
    hasManagerEmails: {
      type: 'boolean',
      description: 'The metadata.managers[*].email field is set',
    },
    hasManagerNames: {
      type: 'boolean',
      description: 'The metadata.managers[*].name field is set',
    },
  },
  handler: teamManagerHandler,
};
