import React from 'react';

import { Box, Button, Menu, MenuItem, Tooltip } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import Settings from '@material-ui/icons/Settings';

import { NavigateToTemplateButton } from '../../NavigateToTemplateButton';

export const GroupConfigActions = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Tooltip title="Configure current team">
        <Button
          aria-controls="config-team-menu"
          aria-haspopup="true"
          onClick={handleClick}
          startIcon={<Settings />}
        >
          Configure Team
        </Button>
      </Tooltip>
      <Menu
        id="config-team-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <NavigateToTemplateButton
            startIcon={<Settings />}
            variant="text"
            template="team-config"
            text="Update Team Metadata"
          />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavigateToTemplateButton
            startIcon={<PeopleIcon />}
            variant="text"
            template="extra-team-members"
            text="Add Extra Team Members"
          />
        </MenuItem>
      </Menu>
    </Box>
  );
};
