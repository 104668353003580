import {
  FactRetrieverContext,
  TechInsightFact,
} from '@backstage-community/plugin-tech-insights-node';
import { Entity } from '@backstage/catalog-model';

import { VulnerabilitiesInfoReader } from '../../VulnerabilitiesInfoReader';
import { CostCenterVulnerabilitiesScore } from '../../types';
import { getEntities } from '../utils/getEntities';

const costCenterAnnotation = 'graph.microsoft.com/cost-center-name';

export const vulnerabilityByCostCenter = (infoList: Record<string, any>[]) => {
  return infoList.reduce(
    (acc: VulnerabilityMapping, vulnerability: Record<string, any>) => {
      const { CostCenter } = vulnerability;
      if (!acc.has(CostCenter)) {
        acc.set(CostCenter, vulnerability);
      }
      return acc;
    },
    new Map<string, Record<string, any>>(),
  );
};

export const teamVulnerabilitiesInfoHandler = (
  reader: VulnerabilitiesInfoReader,
) => {
  return async ({
    logger,
    discovery,
    entityFilter,
    tokenManager,
  }: FactRetrieverContext): Promise<TechInsightFact[]> => {
    const entities = await getEntities({
      entityFilter,
      tokenManager,
      discovery,
    });
    const vulnerabilitiesInfoList =
      await reader.getAllVulnerabilities<CostCenterVulnerabilitiesScore>();
    if (!vulnerabilitiesInfoList) {
      return [];
    }
    const vulnerabilities = vulnerabilityByCostCenter(vulnerabilitiesInfoList);
    const facts: TechInsightFact[] = [];
    entities.items.forEach((entity: Entity) => {
      const costCenter = entity.metadata.annotations?.[costCenterAnnotation];
      if (costCenter) {
        const vulnerability = vulnerabilities.get(costCenter);
        logger.info(
          `Cost center ${costCenter} found for Group entity ${entity.kind} ${
            entity.metadata.name
          } with vulnerability info ${JSON.stringify(vulnerability)}`,
        );
        if (vulnerability) {
          facts.push({
            entity: {
              namespace: entity.metadata.namespace!,
              kind: entity.kind,
              name: entity.metadata.name,
            },
            facts: vulnerability,
          });
        }
      } else {
        logger.info(
          `Cost center not found for Group entity ${entity.kind} ${entity.metadata.name}`,
        );
      }
    });
    return facts;
  };
};

type VulnerabilityMapping = Map<string, Record<string, any>>;
