import React from 'react';

import {
  ANNOTATION_EDIT_URL,
  ANNOTATION_LOCATION,
  GroupEntity,
} from '@backstage/catalog-model';
import { Link } from '@backstage/core-components';

import { IconButton } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '@material-ui/icons/Edit';
import SettingIcon from '@material-ui/icons/Settings';

export const GroupProfileCardAction = (props: {
  group: GroupEntity;
  refreshEntity: () => Promise<void>;
}) => {
  const {
    metadata: { annotations },
  } = props.group;

  const entityLocation = annotations?.[ANNOTATION_LOCATION];
  const allowRefresh =
    entityLocation?.startsWith('url:') || entityLocation?.startsWith('file:');

  const entityMetadataEditUrl =
    props.group.metadata.annotations?.[ANNOTATION_EDIT_URL];

  const infoCardAction = entityMetadataEditUrl ? (
    <IconButton
      aria-label="Edit"
      title="Edit Metadata"
      component={Link}
      to={entityMetadataEditUrl}
      data-testid="edit-button"
      disabled
    >
      <EditIcon />
    </IconButton>
  ) : null;

  const entityConfigUrl =
    props.group.metadata.annotations?.['tomtom.com/team-config-url'];

  const configCardAction = entityConfigUrl ? (
    <IconButton
      color="primary"
      aria-label="Config"
      title="Team Config"
      component={Link}
      to={entityConfigUrl}
      data-testid="config-button"
    >
      <SettingIcon />
    </IconButton>
  ) : null;

  return (
    <>
      {configCardAction}
      {allowRefresh && (
        <IconButton
          aria-label="Refresh"
          title="Schedule entity refresh"
          onClick={props.refreshEntity}
          data-testid="refresh-button"
        >
          <CachedIcon />
        </IconButton>
      )}
      {infoCardAction}
    </>
  );
};
