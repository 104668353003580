import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import React from 'react';

import { useEntity } from '@backstage/plugin-catalog-react';

import { Box, Button, Menu, MenuItem, Tooltip } from '@material-ui/core';

import { NavigateToTemplateButton } from '../../NavigateToTemplateButton';
import { groupFeatures } from './groupFeatures';
import { GroupFeature } from './types';
import { loadEntityFeatures } from './util';

export const GroupEnableFeatureActions = () => {
  const { entity } = useEntity();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const features = loadEntityFeatures(entity, groupFeatures);

  return (
    <Box>
      <Tooltip title="Enable features for the team">
        <Button
          aria-controls="enable-features-menu"
          aria-haspopup="true"
          onClick={handleClick}
          startIcon={<AutoAwesomeIcon color="primary" />}
        >
          Enable Features
        </Button>
      </Tooltip>
      <Menu
        id="enable-features-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {features.map((groupFeature: GroupFeature) => {
          return (
            <MenuItem key={groupFeature.title} onClick={handleClose}>
              <NavigateToTemplateButton
                startIcon={groupFeature.icon}
                variant="text"
                template="config-annotations"
                text={groupFeature.title}
                extraProps={{
                  configType: 'group',
                  annotations: groupFeature.annotations.map(annotation => ({
                    key: annotation.key,
                    value: annotation.value ?? '',
                  })),
                }}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};
