import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'help-page',
});

export const helpPlugin = createPlugin({
  id: 'help',
  routes: {
    root: rootRouteRef,
  },
});

export const HelpPage = helpPlugin.provide(
  createRoutableExtension({
    name: 'HelpPage',
    component: () => import('./HelpPage').then(m => m.HelpPage),
    mountPoint: rootRouteRef,
  }),
);
