import { FactRetrieverContext } from '@backstage-community/plugin-tech-insights-node';
import { Entity } from '@backstage/catalog-model';

import { getEntities } from '../../handlers/utils/getEntities';
import { TypedFactRetriever } from '../../types';

export type TechDocsFact = {
  hasTechDocs: boolean;
};
export const componentEntityTechdocsFactRetriever: TypedFactRetriever<TechDocsFact> =
  {
    id: 'componentEntityTechdocsFactRetriever',
    version: '0.0.1',
    title: 'Entity Ownership',
    description:
      'Generates facts which indicate the quality of related techdocs for the component',
    entityFilter: [{ kind: ['component'] }],
    schema: {
      hasTechDocs: {
        type: 'boolean',
        description: 'component has no techdocs',
      },
    },
    handler: async ({
      discovery,
      entityFilter,
      tokenManager,
    }: FactRetrieverContext) => {
      const entities = await getEntities({
        entityFilter,
        tokenManager,
        discovery,
      });

      return entities.items.map((entity: Entity) => {
        const hasTechDocs =
          Boolean(entity.metadata.annotations?.['backstage.io/techdocs-ref']) ||
          Boolean(
            entity.metadata.annotations?.['backstage.io/techdocs-entity'],
          );

        return {
          entity: {
            namespace: entity.metadata.namespace!,
            kind: entity.kind,
            name: entity.metadata.name,
          },
          facts: {
            hasTechDocs,
          },
        };
      });
    },
  };
