import { useCheckTemplateAndFormData } from '@internal/backstage-plugin-scorecard';
import { useBuildTemplateFormData } from '@internal/plugin-catalog-page-addons-react';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import HelpIcon from '@mui/icons-material/Help';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PersonIcon from '@mui/icons-material/Person';
import SecurityIcon from '@mui/icons-material/Security';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useCallback, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { ActionInput, Check, Scorecard, ScorecardNode } from '../../types';
import { ActionForCheck } from '../../utils/fixActionForCheck';

interface ScoreCheckListProps {
  scorecard: Scorecard;
}

const useStyles = makeStyles({
  failingCheckIcon: {
    color: 'red',
    fontSize: '1.2rem',
  },
  passingCheckIcon: {
    color: 'green',
    fontSize: '1.2rem',
  },
  nameContainer: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '2px',
    paddingLeft: '3px',
  },
  checkItem: {
    cursor: 'pointer',
  },
  selectedItem: {
    backgroundColor: '#DDD',
  },
  categoryTag: {
    minWidth: '128px',
    color: 'white !important',
    '& svg': {
      color: 'white !important',
    },
  },
});

interface DescriptionBoxProps {
  description: string;
  service: string;
  checkId?: string;
  fixCallback?: (input?: ActionInput) => void;
}

export const DescriptionBox = ({
  description,
  service,
  checkId,
  fixCallback,
}: DescriptionBoxProps) => {
  const { template, formData: extraFormData } = useCheckTemplateAndFormData(
    checkId!,
  );
  const { formData } = useBuildTemplateFormData(template);

  const url = `/create/templates/default/${template}?formData=${encodeURIComponent(
    JSON.stringify({ ...formData, ...extraFormData }),
  )}`;
  const onFixClick = useCallback(() => {
    if (fixCallback) {
      fixCallback({ service, templateUrl: url });
    }
  }, [fixCallback, url, service]);
  return (
    <Grid container style={{ height: '100%' }}>
      <Grid item md={1}>
        <Divider orientation="vertical" flexItem style={{ height: '100%' }} />
      </Grid>
      <Grid item md={11}>
        <Box style={{ height: '90%' }}>{description}</Box>
        {fixCallback && (
          <Button variant="contained" color="primary" onClick={onFixClick}>
            Fix
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

interface Column {
  id: 'name' | 'category';
  label: string;
  minWidth?: number;
  align?: 'right' | 'center';
  format?: (
    name: string,
    pass: boolean,
    classes: ClassNameMap,
  ) => React.JSX.Element;
}

const nameFormatter = (name: string, pass: boolean, classes: ClassNameMap) => {
  const Icon = pass ? CheckIcon : ClearIcon;
  return (
    <Grid container>
      <Grid item md={1}>
        <Icon
          className={pass ? classes.passingCheckIcon : classes.failingCheckIcon}
        />
      </Grid>
      <Grid item md={11}>
        <div className={classes.nameContainer}>{name}</div>
      </Grid>
    </Grid>
  );
};

const columns: readonly Column[] = [
  { id: 'name', label: 'Checks', minWidth: 160, format: nameFormatter },
  {
    id: 'category',
    label: 'Category',
    minWidth: 40,
    align: 'right',
  },
];

const colorAndIconForCategory = (category: string | undefined) => {
  let color: string = '#858585';
  let Icon = HelpIcon;

  switch (category) {
    case 'security':
      color = '#5F5EB0';
      Icon = SecurityIcon;
      break;
    case 'ownership':
      color = '#5E7AB0';
      Icon = PersonIcon;
      break;
    case 'quality':
      color = '#5E97B0';
      Icon = CheckCircleIcon;
      break;
    case 'reliability':
      color = '#915EB0';
      Icon = LeaderboardIcon;
      break;
    default:
      break;
  }

  return { color, Icon };
};

const CategoryTag = ({ category }: { category: string | undefined }) => {
  const classes = useStyles();

  const { color, Icon } = useMemo(
    () => colorAndIconForCategory(category),
    [category],
  );

  return (
    <Chip
      style={{
        backgroundColor: color,
        textTransform: 'capitalize',
      }}
      className={classes.categoryTag}
      icon={<Icon />}
      {...(category ? { label: category } : {})}
    />
  );
};
const getRowsFromScorecard = (scorecard: Scorecard) => {
  return scorecard.items.map((item: ScorecardNode) => {
    const check = item as Check;
    return {
      name: check.name,
      id: check.id,
      category: <CategoryTag category={check.category} />,
      pass: check.result,
    };
  });
};

export const ScoreCheckTable = ({ scorecard }: ScoreCheckListProps) => {
  const [selectedCheck, setSelectedCheck] = useState<Check | null>(
    scorecard.items ? (scorecard.items[0] as Check) : null,
  );
  const classes = useStyles();

  const handleClick = useCallback(
    (checkId: string) => {
      const check = scorecard.items.find(
        item => (item as Check).id === checkId,
      );
      setSelectedCheck(check as Check);
    },
    [scorecard.items],
  );

  const rows = scorecard.items ? getRowsFromScorecard(scorecard) : [];

  return (
    <Grid container>
      <Grid item md={8}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography variant="body2">{column.label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.name}
                    onClick={() => handleClick(row.id)}
                    sx={{ td: { border: 0, padding: 0 }, height: '35px' }}
                  >
                    {columns.map(column => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format
                            ? column.format(String(value), row.pass, classes)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item md={4}>
        <DescriptionBox
          description={selectedCheck ? selectedCheck.description : ''}
          service={(scorecard.itemRef ?? '/').split('/')[1]}
          {...(!selectedCheck?.result &&
            (selectedCheck?.id ?? '') in ActionForCheck && {
              fixCallback: ActionForCheck[
                selectedCheck?.id as keyof typeof ActionForCheck
              ] as (input?: ActionInput) => void,
            })}
          checkId={selectedCheck?.id}
        />
      </Grid>
    </Grid>
  );
};
