import { ActionInput } from '../types';

const redirectAction = (url?: string) => {
  if (url) {
    window.location.href = url;
  }
};

const openTemplateAction = ({ templateUrl }: ActionInput) =>
  redirectAction(templateUrl);

const redirectToDashboardAction = ({ service }: ActionInput) => {
  if (service) {
    redirectAction(
      `/catalog/default/component/${service}/watchtower-dashboard`,
    );
  }
};

export const ActionForCheck = {
  // repoUsesLatestCheckoutActionVersionCheck: openTemplateAction,
  groupOwnerCheck: openTemplateAction,
  titleCheck: openTemplateAction,
  descriptionCheck: openTemplateAction,
  techDocsCheck: openTemplateAction,
  serviceSlackChannelCheck: openTemplateAction,
  servicePagerDutyIntegrationCheck: openTemplateAction,
  serviceSonarQubeIntegrationCheck: openTemplateAction,
  serviceMonitoringDashboardCheck: openTemplateAction,
  serviceOperationalStatusCheck: openTemplateAction,
  serviceOperationalTierCheck: openTemplateAction,
  repoHasReadmeCheck: openTemplateAction,
  repoUsesConventionalCommitsCheck: openTemplateAction,
  componentCISuccessCheck: redirectToDashboardAction,
  repoCIMedianBuildDurationCheck: redirectToDashboardAction,
  repoCIMedianPRTimeToMergeCheck: redirectToDashboardAction,
};
